.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.panel {
  min-height: 700px;
  position: relative;
}
