.skills-panel {
  padding: 20px 15px;
  padding-top: 80px;
  .skills-tile-container {
    display: flex;
    flex-wrap: wrap;
  }
}
.skill-panel-header {
  font-size: 6em;
  margin: 0px;
  font-weight: 100;
  font-family: 'Roboto Condensed', sans-serif;
}

.skill-panel-subheader {
  font-size: 2em;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  text-align: left;
  //font-family: 'Roboto Condensed', sans-serif;
  font-weight: 100;
  padding-left: 15px;
  margin-top: 45px;
  margin-bottom: 10px;
}
// .skills-panel-container {
//   transform: rotatez(10deg) rotateX(60deg) rotateZ(25deg);
// }
.skills-panel-header-container {
  overflow: hidden;
}
