.contact-modal {
  width: 400px;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  background-color: white;
  font-size: 15px;
  color: hsl(0, 0%, 30%);
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  height: 350px;

  .contact-modal-contents {
    position: relative;
    height: 100%;
    .again-button-container {
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      .again-button {
        margin-top: 5px;
        background: hsla(199, 0%, 70%, 1);
        color: white;
        font-size: 14px;
        font-weight: bold;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: hsla(199, 0%, 60%, 1);
        }
      }
    }
    .contact-form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    input {
      border-radius: 5px;
      border: 1px solid hsl(0, 0%, 40%);
      padding: 3px 3px;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 14px;
    }
    textarea {
      font-size: 14px;
      line-height: 14px;
      border-radius: 5px;
      border: 1px solid hsl(0, 0%, 40%);
    }
    .close-button {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
    }
    .submit-button {
      background: #85b87a;
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      border: 0px;
      transition: all 0.3s linear;
      outline: none;
      border: 5px solid transparent;
      &.reg {
        cursor: pointer;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
        border-color: #85b87a;
        border-radius: 3px;
        padding: 10px 10px;
        margin: 10px 0px;
        &:hover {
          border-color: hsl(110, 50%, 45%);
          background: hsl(110, 50%, 45%);
        }
      }
      &.success {
        width: 50px;
        position: absolute;
        top: 10%;
        left: calc(50% - 25px);
        border-radius: 50%;
        height: 50px;
        padding: 0px;
        border: 5px solid hsl(110, 50%, 45%);
        color: white;
        font-size: 25px;
        background: hsl(110, 50%, 45%);
      }
      &.error {
        width: 50px;
        position: absolute;
        top: 10%;
        left: calc(50% - 25px);
        border-radius: 50%;
        height: 50px;
        padding: 0px;
        border: 5px solid #ac3939;
        color: white;
        font-size: 25px;
        background: #ac3939;
      }
      &.loading {
        width: 50px;
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        border-radius: 50%;
        height: 50px;
        padding: 0px;
        animation: spin 2s linear infinite;
        animation-delay: 0.3s;
        border: 5px solid hsl(0, 0%, 80%);
        border-top: 5px solid hsl(110, 50%, 45%);
        background: white;
      }
      &:focus {
        transform: scale(0.95);
      }
    }
    .result-container {
      text-align: center;
      position: absolute;
      top: 35%;
      height: 150px;

      width: 100%;
      .title {
        font-size: 24px;
        color: hsl(0, 0%, 20%);
      }
    }
  }
}
.hidden {
  visibility: hidden;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
