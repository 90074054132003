.experience-entry {
  width: 100%;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  //background-color: hsl(0, 0%, 98%);
  cursor: pointer;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.25s linear;
  margin: 20px 0px;
  background-color: white;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    filter: brightness(95%);
  }
  .experience-entry-company {
    text-align: left;
    font-size: 2em;
  }
  .experience-entry-header {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    color: hsl(0, 0%, 40%);
    .experience-entry-header-project {
      text-align: left;
    }
    .experience-entry-header-date {
      text-align: right;
    }
  }
  .experience-entry-expand-icon {
    transform: rotate(180deg);
    transition: all 0.25s linear;
    &.expanded {
      transform: rotate(0deg);
    }
  }
  .company-logo {
    height: 30px;
  }
  .experience-entry-content {
    text-align: left;
    font-size: 1.25em;
    line-height: 1.25em;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .experience-entry {
    font-size: 13px;

    .experience-entry-content {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .experience-entry {
    font-size: 10px;
    .experience-entry-content {
      margin-bottom: 10px;
    }
  }
}
