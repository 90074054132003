.contact-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 180px;
  height: 60px;
  color: white;
  background-size: 200%;
  background-position: 100% 0%;
  font-size: 18px;
  border-radius: 5px;

  .contact-button-container {
    position: relative;
    height: 100%;
    width: 100%;

    &:hover .contact-button-node {
      border-radius: 5px !important;

      &:nth-child(1) {
        pointer-events: inherit;
      }
      &:nth-child(2) {
        transform: translateX(15%);
        background-color: #0b66c2;
        pointer-events: inherit;
      }
      &:nth-child(3) {
        transform: translateX(30%);
        background-color: #222222;
        pointer-events: inherit;
      }
      &:nth-child(4) {
        transform: translateX(45%);
      }
      svg {
        opacity: 1;
      }
    }
    &:hover .contact-button-overlay {
      transform: translateX(-100%);
      opacity: 0;
    }
    .contact-button-overlay {
      &:focus {
        outline: none !important;
      }
      cursor: pointer;
      position: absolute;
      height: 100%;
      width: 100%;
      line-height: 60px;
      border-radius: 3px;
      outline: none !important;
      transition: opacity 0.25s ease-in-out, transform 0.01s;
    }
    .contact-button-content {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 30px;
      color: hsl(0, 0%, 85%);
      justify-content: space-evenly;

      .contact-button-node {
        &:active {
          box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3) !important;
        }
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        width: 60px;
        height: 60px;
        background: hsl(220, 30%, 50%);
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        .fa-envelope-open {
          display: none;
        }
        &:hover {
          box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.3);
          top: -3px;
          filter: brightness(115%);
          svg {
            color: white;
          }
          .fa-envelope {
            display: none;
          }
          .fa-envelope-open {
            display: block;
          }
        }
      }
      svg {
        opacity: 0;
        transition: all 0.25s ease-in;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

@keyframes button-shift {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
