.react-tabs {
  -webkit-tap-highlight-color: transparent;
  font-family: roboto;
}

.react-tabs__tab-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.react-tabs__tab:hover {
  color: #0070c9;
}
.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 0px;
  cursor: pointer;
  outline: none;
  color: rgba(0, 0, 0, 0.35);
  transition: all 0.2s linear;
}
.react-light-tabs {
  color: white;
}

.react-tabs__tab--selected {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.8);
  outline: none;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
}

.react-light-tabs .react-tabs__tab-panel {
  line-height: 25px;
  color: hsl(0, 0%, 80%);
}
.react-tabs__tab-panel {
  display: none;
  line-height: 30px;
  color: hsl(0, 0%, 20%);
  font-size: 16px;
  height: 75px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-light-tabs .react-tabs__tab {
  color: rgba(255, 255, 255, 0.35);
}

.react-light-tabs .react-tabs__tab--selected {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 1);
  outline: none;
}

.react-light-tabs .react-tabs__tab:hover {
  color: #0070c9;
}

.react-light-tabs .react-tabs__tab-list {
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
}
