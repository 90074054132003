.project-panel {
  margin: 50px 0px;
  padding-top: 50px;
  .project-title {
    font-size: 1.75em;
    color: #828282;
    margin-bottom: 15px;

    .project-title-link {
      color: silver;
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #0070c9;
        opacity: 0.6;
        text-decoration: underline;
      }
    }
  }
  .project-panel-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    height: 100vh;
    min-height: 700px;
    align-items: center;
    .project-description {
      width: 500px;
      text-align: left;
      padding: 20px 30px;
      box-sizing: border-box;
    }
  }
  .murderbridge-pic {
    height: 350px;
  }

  .project-video {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.75);
  }
}
.project-panel-header {
  font-size: 6em;
  color: black;
  margin: 50px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1225px) {
  .murderbridge-title {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .project-panel-header {
    font-size: 4em;
  }
  .project-video {
    box-shadow: none !important;
    width: 100vw;
  }
  .project-description {
    font-size: 14px;
    width: 100vw !important;
  }
}
