.gravity-shell {
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.gravity-video {
  height: 100vh;
  min-height: 500px;
  object-fit: cover;
  width: 100%;
}

.gravity-content {
  position: absolute;
  top: 0%;
}
