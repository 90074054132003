.experience-panel-container {
  padding-top: 100px;
  margin-top: -50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  color: hsla(0, 0, 20, 1);
  margin-bottom: 50px;
  .experience-panel {
    max-width: 1000px;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    .experience-header {
      text-align: left;
      font-size: 3em;
    }
    .experience-header-divider {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
}
