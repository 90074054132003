.react-bokeh-shell {
  height: 100vh;
  width: 100%;
  background: black;
  position: relative;
}
.react-bokeh-content {
  position: absolute;
  top: 0%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
.react-bokeh-title {
  font-size: 2em;
  color: white;
}
